import { getEnvironment } from '../util/app';
const version = '2.0.16'; //<- UPDATE VERSION HERE
const remoteScript = 'remoteEntry.js';
const envUrlMap = {
    localhost: `http://localhost:9501/${remoteScript}`,
    dev: `https://securecdn-ist1.jpmchase.net/web/library_unminified/smbpp/payment-portal-ui-remote/${version}/${remoteScript}`,
    test: `https://static2-qa2.chasecdn.com/web/library/smbpp/payment-portal-ui-remote/${version}/${remoteScript}`,
    prod: `https://static.chasecdn.com/web/library/smbpp/payment-portal-ui-remote/${version}/${remoteScript}`,
};
/* END REMOTE CONFIGURATION */
const host = window.location.hostname;
const currentEnvironment = getEnvironment(host);
const deployMap = {
    localhost: envUrlMap.localhost,
    dev: envUrlMap.dev,
    ist: envUrlMap.dev,
    sit: envUrlMap.dev,
    test: envUrlMap.test,
    qa: envUrlMap.test,
    prod: envUrlMap.prod,
};
const remoteUrl = deployMap[currentEnvironment];
const config = {
    paymentPortalUiRemote: {
        remoteUrl: remoteUrl,
    },
};
export { config };
